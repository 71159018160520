
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { InspectionTime, Listing } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

@Component
export default class InspectionCard extends Vue {
  @Prop()
  private readonly inspectionTime!: InspectionTime;

  @Prop()
  private readonly listing!: Listing;

  handleClicked() {
    API.Listings.GetOpenHomeICS(this.listing.Id, this.inspectionTime.StartDate, this.inspectionTime.EndDate).then((ics) => {
      const fileParts = [ics.VCalContent];
      const blob = new Blob(fileParts, { type: 'text/calendar' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      a.href = url;
      a.download = `${this.listing.Address.MicroAddress}.ics`;
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.remove();
      }, 10);
    });
  }
}
